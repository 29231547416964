// Definir variáveis para reutilização de cores
$cinza-meio-claro: #ccc;

// Estilização da tabela com classe
.news-table {
  width: 100%;
  border-collapse: collapse;
  border: none; // Remover borda da tabela
  
  // Estilização das células da tabela
  td, th {
    padding: 10px;
    border: none; // Remover borda das células
  }

  // Estilização da primeira coluna
  td:first-child, th:first-child {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
    white-space: nowrap; // Evitar quebra de linha

    // Aplicar cor cinza ao texto
    color: $cinza-meio-claro;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
      width: 10px;
      z-index: -1;
    }
  }

  // Estilização da segunda coluna
  td:nth-child(2), th:nth-child(2) {
    background-color: white;
  }

  // Estilização do texto na segunda coluna
  td:nth-child(2) {
    font-weight: bold;
  }
}

