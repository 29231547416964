.cards {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.card {
    flex: 0 1 24%;
}

h2 > img {
    margin: 0;
    display: inline;
}

#bulb-find-me-on-the-internet > img{
    margin: 0;
    display: inline;
}
